import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import CustomizedAvatar from "../Custom/CustomizedAvatar";
import { IContactPerson, ISalesPerson } from "../../types/Contact/contact";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

type Props = {
  person: IContactPerson | ISalesPerson;
  remove: () => void;
};

const PersonCard = ({ person, remove }: Props) => {
  const [anchorPos, setAnchorPos] = useState(null);

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const showActions = Boolean(anchorPos);

  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        <MenuItem onClick={remove}>{t("contact.utility.delete")}</MenuItem>
      </Menu>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "14px",
          // cursor: "pointer",
          backgroundColor: "primary.light",
        }}
      >
        <CardContent
          sx={{
            "&:last-child": {
              p: 2,
            },
            p: 2,
          }}
        >
          <Box display="flex" gap={2} justifyContent="space-between">
            <Box display="flex" gap={2} alignItems="center">
              <CustomizedAvatar
                avatars={[
                  {
                    unique_id: person.first_name + person.last_name,
                    first_name: person.first_name,
                    last_name: person.last_name,
                  },
                ]}
              />
              <Box>
                <Typography fontWeight={600} color="primary.main">
                  {`${person.title_name}${person.first_name} ${person.last_name}`}
                </Typography>
                <Typography>{person.position}</Typography>
              </Box>
            </Box>
            <IconButton
              onClick={openActionsHandler}
              sx={{
                height: "fit-content",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PersonCard;
