import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controller/ControlledTextField";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

const UserContactInfo = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <CustomizedBox margin="1rem 0 1rem 0">
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("contact.contact_channel.index")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            name="phone"
            control={control}
            label={t("user.account.phone")}
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message?.toString()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            name="email"
            control={control}
            label={t("user.account.email")}
            error={Boolean(errors.email)}
            helperText={errors.email?.message?.toString()}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default UserContactInfo;
