import { useFormContext, useFieldArray } from "react-hook-form";
import { IContact } from "../../../types/Contact/contact";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import useAttributeColumn from "../../../hooks/use-attribute-column";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controller/ControlledTextField";
import ClearIcon from "@mui/icons-material/Clear";
import CustomizedButton from "../../Custom/CustomizedButton";
import { t } from "i18next";

const ContactAttribute = () => {
  const { control } = useFormContext<IContact>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attribute_list",
  });

  const addAttributeHandler = () => {
    append({ name: "test", short_name: "t", value: "test" });
  };

  const removeAttributeHandler = (index: number) => {
    remove(index);
  };

  const headers = useAttributeColumn(false);

  return (
    <>
      <CustomizedBox maxWidth={1040} bgcolor="primary.light">
        <TableContainer>
          <Table sx={{ overflow: "scroll" }}>
            <TableHead sx={{ bgcolor: "primary.light" }}>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    align={header.align ? header.align : "center"}
                    key={index}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={header.width}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color="primary.main"
                    >
                      {header.thaiLabel}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((attribute, index) => (
                <TableRow key={attribute.id}>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <Typography fontSize={14}>{attribute.name}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <Typography fontSize={14}>
                      {attribute.short_name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <ControlledTextField
                      control={control}
                      name={`attribute_list.${index}.value`}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        removeAttributeHandler(index);
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomizedBox>
      <CustomizedButton
        title={t("button.add") + t("contact.attribute.index")}
        variant="outlined"
        onClick={addAttributeHandler}
      />
    </>
  );
};

export default ContactAttribute;
