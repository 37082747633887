import { Grid } from "@mui/material";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledSelect from "../../Controller/ControlledSelect";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { ICompany } from "../../../types/Setting/Company";

type Props = {
  index: number;
};

const AddressDetail = ({ index }: Props) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<ICompany>();
  const { t } = useTranslation();

  const defaultAddressValue = useWatch({
    control,
    name: `address_list.0`,
  });

  const isSameAsDefault = useWatch({
    control,
    name: `address_list.${index}.is_same_as_default_address`,
  });

  useEffect(() => {
    if (isSameAsDefault) {
      setValue(`address_list.${index}`, {
        ...defaultAddressValue,
        is_same_as_default_address: true,
        type: index === 1 ? "ที่อยู่จัดส่งสินค้า" : "",
      });
    }
  }, [defaultAddressValue, index, isSameAsDefault, setValue]);

  return (
    <>
      <Grid item xs={12}>
        <ControlledTextField
          name={`address_list.${index}.contact_name`}
          control={control}
          label={t("contact.address.contact_person_name")}
          error={Boolean(errors.address_list?.[index]?.contact_name)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.contact_name &&
            errors.address_list?.[index]?.contact_name?.message
          }
          disabled={isSameAsDefault}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <ControlledTextField
          name={`address_list.${index}.contact_phone`}
          control={control}
          label={t("contact.address.phone_number")}
          error={Boolean(errors.address_list?.[index]?.contact_phone)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.contact_phone &&
            errors.address_list?.[index]?.contact_phone?.message
          }
          disabled={isSameAsDefault}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <ControlledTextField
          name={`address_list.${index}.fax`}
          control={control}
          label={t("contact.address.fax")}
          error={Boolean(errors.address_list?.[index]?.fax)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.fax &&
            errors.address_list?.[index]?.fax?.message
          }
          disabled={isSameAsDefault}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name={`address_list.${index}.address`}
          control={control}
          label={t("contact.address.index")}
          error={Boolean(errors.address_list?.[index]?.address)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.address &&
            errors.address_list?.[index]?.address?.message
          }
          rows={4}
          multiline
          disabled={isSameAsDefault}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledSelect
          name={`address_list.${index}.sub_district`}
          control={control}
          label={t("contact.address.sub_district")}
          error={Boolean(errors.address_list?.[index]?.sub_district)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.sub_district &&
            errors.address_list?.[index]?.sub_district?.message
          }
          options={[]}
          disabled={isSameAsDefault}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledSelect
          name={`address_list.${index}.district`}
          control={control}
          label={t("contact.address.district")}
          error={Boolean(errors.address_list?.[index]?.district)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.district &&
            errors.address_list?.[index]?.district?.message
          }
          options={[]}
          disabled={isSameAsDefault}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ControlledSelect
          name={`address_list.${index}.province`}
          control={control}
          label={t("contact.address.province")}
          error={Boolean(errors.address_list?.[index]?.province)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.province &&
            errors.address_list?.[index]?.province?.message
          }
          options={[]}
          disabled={isSameAsDefault}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <ControlledTextField
          name={`address_list.${index}.postal_code`}
          control={control}
          label={t("contact.address.postal_code")}
          error={Boolean(errors.address_list?.[index]?.postal_code)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.postal_code &&
            errors.address_list?.[index]?.postal_code?.message
          }
          disabled={isSameAsDefault}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <ControlledSelect
          name={`address_list.${index}.country`}
          control={control}
          label={t("contact.address.country")}
          error={Boolean(errors.address_list?.[index]?.country)}
          helperText={
            errors.address_list &&
            errors.address_list?.[index]?.country &&
            errors.address_list?.[index]?.country?.message
          }
          options={[]}
          disabled={isSameAsDefault}
        />
      </Grid>
    </>
  );
};

export default AddressDetail;
