import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";

interface ILocation {
  branch_id: string;
}

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  onAddHandler: (data: ILocation) => void;
};

const locationSettingSchema: ILocation = {
  branch_id: "",
};

const LocationModal = ({ open, closeModalHandler, onAddHandler }: Props) => {
  //   const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: locationSettingSchema,
    // resolver: yupResolver(),
  });

  const onClose = () => {
    reset();
    closeModalHandler();
  };

  //   useEffect(() => {
  //     if (isSuccess) {
  //       reset(formatLevel1);
  //     }
  //   }, [reset]);

  return (
    <ModalUI
      title={t("button.add")}
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />

          <CustomizedButton
            title={t("button.add")}
            variant="contained"
            onClick={handleSubmit(onAddHandler)}
          />
        </Box>
      }
    >
      <Box mt={1}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ControlledTextField
              label={t("setting.inventory.warehouse.branch_id")}
              control={control}
              name="branch_id"
              error={Boolean(errors?.branch_id)}
              helperText={
                errors?.branch_id && errors.branch_id.message?.toString()
              }
              required
            />
          </Grid>
        </Grid>
      </Box>
    </ModalUI>
  );
};

export default LocationModal;
