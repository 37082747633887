import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import DropzoneUI from "../../UI/DropzoneUI";
import { contactPersonSchema } from "../../Form/Contact/schema";
import { IContactPerson } from "../../../types/Contact/contact";
import ControlledSelect from "../../Controller/ControlledSelect";
import { Fragment } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { contactChannelOptions } from "../../../utils/Global";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  currentId?: number;
  onAddHandler: (data: IContactPerson) => void;
};

const PersonModal = ({
  open,
  closeModalHandler,
  currentId,
  onAddHandler,
}: Props) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IContactPerson>({
    defaultValues: contactPersonSchema,
    // resolver: yupResolver(),
  });
  //   const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contact_channel_list",
  });

  const addContactChannel = () => {
    append({
      contact_channel_type: "",
      contact_channel_info: "",
    });
  };

  const removeContactChannel = (index: number) => {
    remove(index);
  };

  const onClose = () => {
    reset();
    closeModalHandler();
  };
  return (
    <ModalUI
      title={
        currentId
          ? t("sentence.edit")
          : t("button.add") + t("contact.contact_person.index")
      }
      open={open}
      handleClose={onClose}
      maxWidth="md"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {currentId ? (
            <CustomizedButton title={t("button.save")} variant="contained" />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={handleSubmit(onAddHandler)}
            />
          )}
        </Box>
      }
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Typography color="primary.main" fontWeight={600} mb={3}>
            ช้อมูล
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12}>
              <ControlledTextField
                name="position"
                control={control}
                label={t(`contact.contact_person.position`)}
                error={Boolean(errors.position)}
                helperText={errors.position?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name="title_name"
                control={control}
                label={t("contact.contact_person.prefix.index")}
                error={Boolean(errors.title_name)}
                helperText={errors.title_name?.message?.toString()}
                options={[]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                name="first_name"
                control={control}
                label={t(`contact.contact_person.name.first_name`)}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                name="last_name"
                control={control}
                label={t(`contact.contact_person.name.last_name`)}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ControlledTextField
                name="nick_name"
                control={control}
                label={t(`contact.contact_person.name.nick_name`)}
                error={Boolean(errors.nick_name)}
                helperText={errors.nick_name?.message?.toString()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            รูปภาพ
          </Typography>
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={false}
          />
        </Grid>
      </Grid>
      <Typography color="primary.main" fontWeight={600} my={2}>
        {t("contact.contact_channel.index")}
      </Typography>
      <Grid container spacing={1.5}>
        {fields.map((channel, index) => (
          <Fragment key={channel.id}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledSelect
                name={`contact_channel_list.${index}.contact_channel_type`}
                control={control}
                label={t("contact.contact_channel.type")}
                options={contactChannelOptions}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <ControlledTextField
                  name={`contact_channel_list.${index}.contact_channel_info`}
                  control={control}
                  label={t("contact.contact_channel.info")}
                  error={Boolean(
                    errors.contact_channel_list?.[index]?.contact_channel_info
                  )}
                  helperText={
                    errors.contact_channel_list &&
                    errors.contact_channel_list?.[index]
                      ?.contact_channel_info &&
                    errors.contact_channel_list?.[index]?.contact_channel_info
                      ?.message
                  }
                />
                <IconButton onClick={() => removeContactChannel(index)}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <CustomizedButton
        sx={{
          mt: 2,
        }}
        title={t("button.add") + t("contact.contact_channel.index")}
        variant="outlined"
        onClick={addContactChannel}
      />
      <Typography color="primary.main" fontWeight={600} my={2}>
        {t("sentence.remark")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("sentence.remark")}
            control={control}
            name="remark"
            error={Boolean(errors?.remark)}
            helperText={errors?.remark && errors.remark.message?.toString()}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default PersonModal;
