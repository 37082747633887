import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, IGlobalSetting } from "../../../types/global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GlobalSettingTable from "../../../components/Table/Setting/Global";
import { useModal } from "../../../hooks/use-modal";
import GlobalSettingModal from "../../../components/UI/Modal/GlobalSettingModal";
import { useState } from "react";

const DepartmentSetting = () => {
  const { t } = useTranslation();
  const [currentId, setCurrentId] = useState<number>();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.employee.index"),
      to: "/setting/employee",
    },
    {
      name: t("setting.employee.department"),
    },
  ];

  const onAddHandler = (data: IGlobalSetting) => {
    console.log("add");
  };

  const onEditHandler = (id?: number) => {
    setCurrentId(id);
    openModalHandler();
  };

  const onUpdateHandler = (data: IGlobalSetting) => {
    console.log("update");
  };

  const onDeleteHandler = (id?: number) => {
    setCurrentId(id);
    console.log("delete");
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("setting.employee.department")}</Typography>
        <CustomizedButton
          title={t("button.add") + t("setting.employee.department")}
          variant="contained"
          onClick={openModalHandler}
        />
      </Box>
      <GlobalSettingTable
        data={[]}
        onEditHandler={onEditHandler}
        onDeleteHandler={onDeleteHandler}
      />
      <GlobalSettingModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={onAddHandler}
        onUpdateHandler={onUpdateHandler}
        currentId={currentId}
      />
    </>
  );
};

export default DepartmentSetting;
