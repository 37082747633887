import { Grid, Box } from "@mui/material";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { useFormContext } from "react-hook-form";
import { IContact } from "../../../types/Contact/contact";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useTranslation } from "react-i18next";

const ContactMember = () => {
  const { control } = useFormContext<IContact>();
  const { t } = useTranslation();
  return (
    <Box mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="unique_id"
            label={"รหัส" + t("contact.member.index")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledDatePicker
            control={control}
            name="opened_date"
            label={t("date.opened_date")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledDatePicker
            control={control}
            name="expired_date"
            label={t("date.expired_date")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactMember;
