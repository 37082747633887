import DocumentDropzoneUI from "../../UI/DocumentDropzoneUI";
import { useFormContext } from "react-hook-form";

const ContactAttachment = () => {
  const { control, setValue } = useFormContext();
  return (
    <DocumentDropzoneUI
      control={control}
      name="attachment_list"
      setValue={setValue}
    />
  );
};

export default ContactAttachment;
