import { FormControlLabel, Switch, TextFieldProps } from "@mui/material";
import { Control, Controller } from "react-hook-form";

type Props = {
  name: string;
  label?: TextFieldProps["label"];
  control: Control<any>;
  disabled?: boolean;
};

const ControlledSwitch = ({ name, control, label, disabled }: Props) => {
  return (
    <Controller
      key={`${name}-switch`}
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              {...field}
              //   checked={checked}
              //   onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          disabled={disabled}
          label={label}
        />
      )}
    />
  );
};

export default ControlledSwitch;
