import { ISelectOption } from "../types/global";

export const contactChannelOptions: ISelectOption[] = [
  {
    label: "เบอร์โทรศัพท์",
    value: "phone",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "แฟกซ์",
    value: "fax",
  },
  {
    label: "Line",
    value: "line",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Website",
    value: "website",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "อื่นๆ",
    value: "other",
  },
];
