import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import ContactTable from "../../../components/Table/Contact";

const Customer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t("contact.contact_type.customer"),
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">
          {t("contact.contact_type.customer")}
        </Typography>
        <CustomizedButton
          title={`${t("button.add")}${t("contact.contact_type.customer")}`}
          variant="contained"
          onClick={() => navigate(`${pathname}/add?tab=address`)}
        />
      </Box>
      <ContactTable type="customer" />
    </>
  );
};

export default Customer;
