import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ControlledTextField from "../../Controller/ControlledTextField";

const UserSecurityInfo = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <CustomizedBox margin="0 0 1rem 0">
      <Typography color="primary.main" fontWeight={600} mb={2}>
        ความปลอดภัย
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormControl
                disabled={false}
                variant="outlined"
                fullWidth
                size="small"
                required
              >
                <InputLabel htmlFor="outlined-adornment-password1">
                  {t("user.account.password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password1"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        disabled={false}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("user.account.password")}
                  {...field}
                />
                <FormHelperText
                  id="helper-text-password1"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {errors.password?.message?.toString()}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            label={t("user.account.confirm_password")}
            name="confirm_password"
            control={control}
            required
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default UserSecurityInfo;
