import { Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controller/ControlledTextField";
import { IContact } from "../../../types/Contact/contact";
import { useFormContext } from "react-hook-form";

const ContactTag = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IContact>();
  const { t } = useTranslation();

  return (
    <CustomizedBox>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("contact.tag_list")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledTextField
            placeholder={t("contact.tag_list")}
            name="tag_list"
            control={control}
            error={Boolean(errors.tag_list)}
            helperText={errors.tag_list?.message?.toString()}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ContactTag;
