import { SxProps } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import CustomizedCheckbox from "../Custom/CustomizedCheckbox";

type Props = {
  sx?: SxProps;
  label?: string;
  error?: boolean;
  testId?: string;
  control: Control<any>;
  name: string;
  disabled?: boolean;
};

const ControlledCheckbox = ({ control, name, label, disabled }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedCheckbox {...field} label={label} isDisabled={disabled} />
      )}
    />
  );
};

export default ControlledCheckbox;
