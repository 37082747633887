import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../types/global";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";

const User = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.account.index"),
      to: "account",
    },
    // {
    //   title: t("user.rbac.index"),
    //   navigateTo: "rbac",
    // },
    // {
    //   title: t("user.setting.index"),
    //   navigateTo: "setting",
    // },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default User;
