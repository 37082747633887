import { Box, Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import DropzoneUI from "../../UI/DropzoneUI";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";
import ControlledSwitch from "../../Controller/ControlledSwitch";

const UserGeneralInfo = () => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation();

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const genders = [
    {
      id: 1,
      label: t("user.account.gender.male"),
      value: "male",
    },
    {
      id: 2,
      label: t("user.account.gender.female"),
      value: "female",
    },
    {
      id: 3,
      label: t("user.account.gender.other"),
      value: "other",
    },
  ];

  return (
    <CustomizedBox margin={0}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            ข้อมูลทั่วไป
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="unique_id"
                control={control}
                label={t("user.account.id")}
                error={Boolean(errors.unique_id)}
                helperText={errors.unique_id?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} />
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledSelect
                name="title_name"
                control={control}
                label={t("user.account.title_name")}
                error={Boolean(errors.title_name)}
                helperText={errors.title_name?.message?.toString()}
                options={prefixes}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="nick_name"
                control={control}
                label={t("user.account.nick_name")}
                error={Boolean(errors.nick_name)}
                helperText={errors.nick_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="first_name"
                control={control}
                label={t("user.account.first_name")}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name?.message?.toString()}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="last_name"
                control={control}
                label={t("user.account.last_name")}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name?.message?.toString()}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledSelect
                name="gender"
                control={control}
                label={t("user.account.gender.index")}
                error={Boolean(errors.gender)}
                helperText={errors.gender?.message?.toString()}
                options={genders}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledDatePicker
                name="start_date"
                control={control}
                label={t("date.start_date")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledCheckbox
                name="is_sales_employee"
                control={control}
                label="พนักงานขาย"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            รูปภาพ
          </Typography>
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={false}
          />
          <Box mt={2}>
            <ControlledSwitch
              control={control}
              name="status"
              label="ใช้งาน"
              disabled={false}
            />
          </Box>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default UserGeneralInfo;
