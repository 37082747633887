import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IModalUI } from "../../../types/global";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    paddingTop: "8px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    paddingTop: "0",
  },
}));

const ModalUI = ({
  open,
  handleClose,
  fullWidth,
  maxWidth,
  title,
  titleSize,
  children,
  width,
  isLoading,
  isRed,
  startIcon,
  action,
}: IModalUI) => {
  return (
    <StyledDialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: width ?? "auto",
          },
        },
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isLoading && <LinearProgress />}
      {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}

      {startIcon ? (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", m: 0, p: 2 }}>
          {startIcon}
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              color: isRed ?? "primary.main",
              padding: 0,
              fontSize: titleSize ? titleSize : "18px",
            }}
          >
            {title}
          </DialogTitle>
        </Box>
      ) : (
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            m: 0,
            p: 2,
            color: isRed ?? "primary.main",
            fontSize: titleSize ? titleSize : "18px",
          }}
        >
          {title}
        </DialogTitle>
      )}

      <DialogContent>{children}</DialogContent>
      {action && <DialogActions>{action}</DialogActions>}
    </StyledDialog>
  );
};

export default ModalUI;
