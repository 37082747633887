import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useForm, FormProvider } from "react-hook-form";
import ContactDetail from "../../../components/Form/Contact/Detail";
import { contactSchema } from "../../../components/Form/Contact/schema";
import { IContact } from "../../../types/Contact/contact";
import ContactChannel from "../../../components/Form/Contact/ContactChannel";
import ContactTag from "../../../components/Form/Contact/Tag";
import ContactRemark from "../../../components/Form/Contact/Remark";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { useEffect, useState } from "react";
import ContactAddress from "../../../components/Form/Contact/Address";
import ContactPersonList from "../../../components/Form/Contact/ContactPersonList";
import ContactFinance from "../../../components/Form/Contact/Finance";
import ContactAttribute from "../../../components/Form/Contact/Attribute";
import SalesPersonList from "../../../components/Form/Contact/SalesPersonList";
import ContactAttachment from "../../../components/Form/Contact/Attachment";
import ContactMember from "../../../components/Form/Contact/Member";

const CustomerContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(`${pathname}?tab=address`);

  const tabs: ITab[] = [
    {
      label: t("contact.address.index"),
      path: `${pathname}?tab=address`,
    },
    {
      label: t("contact.contact_person.index"),
      path: `${pathname}?tab=contact_person`,
    },
    {
      label: t("contact.financial.index"),
      path: `${pathname}?tab=financial`,
    },
    {
      label: "ข้อมูล" + t("contact.attribute.index"),
      path: `${pathname}?tab=attribute`,
    },
    {
      label: t("contact.attachment.index"),
      path: `${pathname}?tab=attachment`,
    },
    {
      label: t("contact.sales_person.index"),
      path: `${pathname}?tab=sales_person`,
    },
    {
      label: t("contact.member.index"),
      path: `${pathname}?tab=member`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "address":
        setCurrentTab(pathname + "?tab=address");
        break;
      case "contact_person":
        setCurrentTab(pathname + "?tab=contact_person");
        break;
      case "financial":
        setCurrentTab(pathname + "?tab=financial");
        break;
      case "attribute":
        setCurrentTab(pathname + "?tab=attribute");
        break;
      case "attachment":
        setCurrentTab(pathname + "?tab=attachment");
        break;
      case "sales_person":
        setCurrentTab(pathname + "?tab=sales_person");
        break;
      case "member":
        setCurrentTab(pathname + "?tab=member");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t("contact.contact_type.customer"),
      to: "/contact/customer",
    },
    {
      name:
        id && Boolean(id)
          ? id
          : t(`${t("button.add")}${t("contact.contact_type.customer")}`),
    },
  ];

  const methods = useForm<IContact>({
    defaultValues: contactSchema,
  });

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "address":
        return <ContactAddress />;
      case "contact_person":
        return <ContactPersonList />;
      case "financial":
        return <ContactFinance />;
      case "attribute":
        return <ContactAttribute />;
      case "attachment":
        return <ContactAttachment />;
      case "sales_person":
        return <SalesPersonList />;
      case "member":
        return <ContactMember />;
      default:
        return null;
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <FormProvider {...methods}>
        <ContactDetail type="customer" />
        <ContactChannel />
        <ContactTag />
        <ContactRemark />
        <CustomizedBox>
          <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
          {renderTab(tab)}
        </CustomizedBox>
      </FormProvider>
    </>
  );
};

export default CustomerContainer;
