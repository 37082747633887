import { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
// import CustomizedTextField from "../Custom/CustomizedTextField";
import { TextField, TextFieldProps } from "@mui/material";
import { StandardLonghandProperties } from "../../../node_modules/csstype/index";

type ExtendedNumberTextFieldProps = {
  disabled?: TextFieldProps["disabled"];
  control: Control<any>;
  label?: TextFieldProps["label"];
  name: string;
  error?: TextFieldProps["error"];
  sx?: TextFieldProps["sx"];
  helperText?: TextFieldProps["helperText"];
  onKeyDown?: TextFieldProps["onKeyDown"];
  textAlign?: StandardLonghandProperties["textAlign"];
  InputLabelProps?: TextFieldProps["InputLabelProps"];
  InputProps: TextFieldProps["InputProps"];
  pricePerUnit?: number;
  helperTextAlign?: StandardLonghandProperties["textAlign"];
  fullWidth?: boolean;
};

const ControlledNumberTextField = ({
  disabled,
  control,
  label,
  name,
  error,
  sx,
  helperText,
  pricePerUnit,
  onKeyDown,
  textAlign,
  InputLabelProps,
  helperTextAlign,
  fullWidth,
  InputProps,
}: ExtendedNumberTextFieldProps) => {
  const [warn, setWarn] = useState(false);
  const showWarning = () => setWarn(true);
  const hideWarning = () => setWarn(false);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <NumericFormat
          fullWidth={fullWidth}
          size="small"
          label={label}
          value={field.value}
          allowNegative={false}
          thousandSeparator=","
          decimalScale={2}
          onValueChange={(v) => {
            field.onChange(v.floatValue || "");
            if (pricePerUnit) {
              const value = v.floatValue || 0;
              if (pricePerUnit > value) {
                showWarning();
              } else hideWarning();
            }
          }}
          disabled={disabled}
          customInput={TextField}
          helperText={helperText}
          error={error || warn}
          inputProps={{
            style: { textAlign: textAlign ? textAlign : "right" },
          }}
          InputProps={InputProps}
          InputLabelProps={InputLabelProps}
          FormHelperTextProps={{
            style: {
              fontSize: "10px",
              textAlign: helperTextAlign ? "right" : "left",
            },
          }}
          placeholder="0.00"
          onKeyDown={onKeyDown}
          sx={sx}
          fixedDecimalScale
        />
      )}
    />
  );
};

export default ControlledNumberTextField;
