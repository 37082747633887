import { useEffect, useState } from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Box, IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
};

const useBankAccountColumnDefs = (
  onEditHandler: Props["onEditHandler"],
  onDeleteHandler: Props["onDeleteHandler"]
) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("setting.unique_id"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "account_no",
        headerName: t("setting.sales.bank_account.account_no"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "name",
        headerName: t("setting.sales.bank_account.name"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "bank_name",
        headerName: t("setting.sales.bank_account.bank_name"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "type",
        headerName: t("setting.sales.bank_account.type"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "currency",
        headerName: t("setting.sales.bank_account.currency"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "description",
        headerName: t("setting.description"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "",
        filter: false,
        sortable: false,
        width: 100,
        cellStyle: {
          display: "flex",
          justifyContent: "center",
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data) {
            return (
              <Box>
                <IconButton onClick={() => onEditHandler(params.data?.id)}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton onClick={() => onDeleteHandler(params.data?.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            );
          }
        },
      },
    ]);
  }, [onDeleteHandler, onEditHandler, t]);
  return columnDefs;
};

export default useBankAccountColumnDefs;
