import { IContact, IContactPerson } from "../../../types/Contact/contact";

export const contactSchema: IContact = {
  unique_id: "",
  business_type: "",
  business_type_detail: "",
  name: "",
  taxpayer_id: "",
  type: "",
  source: "",
  group: "",
  zone: "",
  sales_channel: "",
  contact_channel_list: [
    {
      contact_channel_type: "phone",
      contact_channel_info: "",
    },
    {
      contact_channel_type: "email",
      contact_channel_info: "",
    },
  ],
  tag_list: [],
  remark: "",
  address_list: [
    {
      type: "ที่อยู่จดทะเบียน",
      address: "",
      district: "",
      is_default: true,
      postal_code: "",
      province: "",
      sub_district: "",
      contact_name: "",
      contact_phone: "",
      country: "",
      fax: "",
    },
    {
      type: "ที่อยู่จัดส่งสินค้า",
      address: "",
      district: "",
      is_default: false,
      is_same_as_default_address: false,
      postal_code: "",
      province: "",
      sub_district: "",
      contact_name: "",
      contact_phone: "",
      country: "",
      fax: "",
    },
  ],
  contact_person_list: [],
  credit_limit: 0,
  credit_day: 0,
  attribute_list: [],
  attachment_list: [],
  sales_person_list: [],
  member_detail: {
    unique_id: "",
    opened_date: null,
    expired_date: null,
  },
};

export const contactPersonSchema: IContactPerson = {
  position: "",
  title_name: "",
  first_name: "",
  last_name: "",
  nick_name: "",
  img_url: [],
  contact_channel_list: [
    {
      contact_channel_type: "",
      contact_channel_info: "",
    },
  ],
  remark: "",
};
