import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useModal } from "../../../hooks/use-modal";
import LocationModal from "../../../components/UI/Modal/LocationModal";

const LocationSetting = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.location.index"),
    },
  ];

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? ["1", "5", "6", "7"] : []
    );
  };

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const onAddHandler = () => {
    console.log("add");
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <Typography variant="h5">
          {t("setting.inventory.location.index")}
        </Typography>
        <CustomizedButton
          title={t("button.add") + t("setting.inventory.location.index")}
          variant="contained"
          onClick={openModalHandler}
        />
      </Box>
      <CustomizedBox>
        <Box
          onClick={handleExpandClick}
          sx={{ cursor: "pointer", width: "fit-content", mb: 3 }}
          display={"flex"}
        >
          {expanded.length === 0 ? (
            <KeyboardArrowRightIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
          <Typography>
            {expanded.length === 0 ? "เปิดทั้งหมด" : "ปิดทั้งหมด"}
          </Typography>
        </Box>
        <TreeView
          aria-label="controlled"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={handleToggle}
        >
          <TreeItem nodeId="1" label="Applications">
            <TreeItem nodeId="2" label="Calendar" />
            <TreeItem nodeId="3" label="Chrome" />
            <TreeItem nodeId="4" label="Webstorm" />
          </TreeItem>
          <TreeItem nodeId="5" label="Documents">
            <TreeItem nodeId="6" label="MUI">
              <TreeItem nodeId="7" label="src">
                <TreeItem nodeId="8" label="index.js" />
                <TreeItem nodeId="9" label="tree-view.js" />
              </TreeItem>
            </TreeItem>
          </TreeItem>
        </TreeView>
      </CustomizedBox>
      <LocationModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={onAddHandler}
      />
    </>
  );
};

export default LocationSetting;
