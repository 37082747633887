import { ColDef } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../Custom/CustomizedStatus";

const useContactColumnDefs = (type: "customer" | "vendor") => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: `รหัส${t(`contact.contact_type.${type}`)}`,
        filter: "agTextColumnFilter",
      },
      {
        field: "business_type",
        headerName: t("contact.info.business_type"),
        filter: "agSetColumnFilter",
      },
      {
        field: "name",
        headerName: t("contact.info.brand_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "contact_channel_list",
        headerName: t("contact.contact_channel.index"),
        filter: "agTextColumnFilter",
      },
      {
        field: "tag_list",
        headerName: t("contact.tag_list"),
        filter: "agSetColumnFilter",
      },
      {
        field: "status",
        headerName: t("contact.contact_status"),
        filter: "agTextColumnFilter",
        cellRenderer: ({ value }: { value: number }) => {
          return <CustomizedStatus status={value ? "active" : "inactive"} />;
        },
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    ]);
  }, [t, type]);

  return columnDefs;
};

export default useContactColumnDefs;
