import UserContactInfo from "../../../components/Form/User/ContactInfo";
import UserDutyInfo from "../../../components/Form/User/DutyInfo";
import UserGeneralInfo from "../../../components/Form/User/GeneralInfo";
import UserSecurityInfo from "../../../components/Form/User/SecurityInfo";

const AccountInfoTab = () => {
  return (
    <>
      <UserGeneralInfo />
      <UserContactInfo />
      <UserDutyInfo />
      <UserSecurityInfo />
    </>
  );
};

export default AccountInfoTab;
