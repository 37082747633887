import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import { IGlobalSetting } from "../../../types/global";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  currentId?: number;
  onAddHandler: (data: IGlobalSetting) => void;
  onUpdateHandler: (data: IGlobalSetting) => void;
};

const globalSettingSchema: IGlobalSetting = {
  unique_id: "",
  name_th: "",
  name_en: "",
  description: "",
};

const GlobalSettingModal = ({
  open,
  closeModalHandler,
  currentId,
  onAddHandler,
  onUpdateHandler,
}: Props) => {
  //   const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: globalSettingSchema,
    // resolver: yupResolver(),
  });

  const onClose = () => {
    reset();
    closeModalHandler();
  };

  //   useEffect(() => {
  //     if (isSuccess) {
  //       reset(formatLevel1);
  //     }
  //   }, [reset]);

  return (
    <ModalUI
      title={currentId ? t("sentence.edit") : t("button.add")}
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {currentId ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={handleSubmit(onUpdateHandler)}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={handleSubmit(onAddHandler)}
            />
          )}
        </Box>
      }
    >
      <Typography color="primary.main" fontWeight={600} mb={2} fontSize={14}>
        ข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.unique_id")}
            control={control}
            name="unique_id"
            error={Boolean(errors?.unique_id)}
            helperText={
              errors?.unique_id && errors.unique_id.message?.toString()
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.name_th")}
            control={control}
            name="name_th"
            error={Boolean(errors?.name_th)}
            helperText={errors?.name_th && errors.name_th.message?.toString()}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.name_en")}
            control={control}
            name="name_en"
            error={Boolean(errors?.name_en)}
            helperText={errors?.name_en && errors.name_en.message?.toString()}
          />
        </Grid>
      </Grid>
      <Typography
        color="primary.main"
        fontWeight={600}
        fontSize={14}
        mt={3}
        mb={2}
      >
        คำอธิบาย
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.description")}
            control={control}
            name="description"
            error={Boolean(errors?.description)}
            helperText={
              errors?.description && errors.description.message?.toString()
            }
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default GlobalSettingModal;
