import { useEffect, useRef, useState } from "react";
import AgGrid from "../../UI/AgGrid";
import useContactColumnDefs from "./useContactColumnDefs";
import { useNavigate } from "react-router-dom";
import { RowDoubleClickedEvent } from "ag-grid-community";

type Props = {
  type: "customer" | "vendor";
};

const ContactTable = ({ type }: Props) => {
  const columnDefs = useContactColumnDefs(type);

  const gridRef = useRef();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<[]>([]);

  useEffect(() => {
    setRowData([]);
  }, []);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id } = params.data;
    navigate(`/contact/${type}/${id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      rowData={rowData}
      path={`/contact/${type}`}
      disabledSidebar
      // onGridReady={onGridReady}
    />
  );
};

export default ContactTable;
