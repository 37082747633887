import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Typography, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ControlledSelect from "../../Controller/ControlledSelect";

const UserDutyInfo = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <CustomizedBox margin="0 0 1rem 0">
      <Typography color="primary.main" fontWeight={600} mb={2}>
        จัดการหน้าที่
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledSelect
            name="department"
            control={control}
            label={t("user.account.department")}
            error={Boolean(errors.department)}
            helperText={errors.department?.message?.toString()}
            options={[]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledSelect
            name="position"
            control={control}
            label={t("user.account.position")}
            error={Boolean(errors.position)}
            helperText={errors.position?.message?.toString()}
            options={[]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledSelect
            name="employee_type"
            control={control}
            label={t("user.account.employee_type")}
            error={Boolean(errors.employee_type)}
            helperText={errors.employee_type?.message?.toString()}
            options={[]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledSelect
            name="company_name"
            control={control}
            label={t("user.account.company_name")}
            error={Boolean(errors.company_name)}
            helperText={errors.company_name?.message?.toString()}
            options={[]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledSelect
            name="original_branch"
            control={control}
            label={t("user.account.original_branch")}
            error={Boolean(errors.original_branch)}
            helperText={errors.original_branch?.message?.toString()}
            options={[]}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default UserDutyInfo;
