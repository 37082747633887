import { useFieldArray, useFormContext } from "react-hook-form";
import { IContact } from "../../../types/Contact/contact";
import { useTranslation } from "react-i18next";
import PersonCard from "../../UI/PersonCard";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Box, Grid } from "@mui/material";
// import { useModal } from "../../../hooks/use-modal";
// import PersonModal from "../../UI/Modal/PersonModal";

const SalesPersonList = () => {
  const { control } = useFormContext<IContact>();
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sales_person_list",
  });

  //   const { modal, openModalHandler, closeModalHandler } = useModal();

  const addSalesPersonHandler = () => {
    append({
      unique_id: Math.random().toString(),
      title_name: "Mr.",
      first_name: "Vorapol",
      last_name: "Charoenkitmongkol",
      img_url: [],
      position: "Main",
    });
    //   closeModalHandler();
  };

  const removeSalesPersonHandler = (index: number) => {
    remove(index);
  };
  return (
    <>
      <Box my={2}>
        <Grid container spacing={1.5}>
          {fields.map((person, index) => (
            <PersonCard
              key={person.id}
              person={person}
              remove={() => removeSalesPersonHandler(index)}
            />
          ))}
        </Grid>
      </Box>
      <CustomizedButton
        variant="outlined"
        title={`${t("button.add")}${t("contact.sales_person.index")}`}
        onClick={addSalesPersonHandler}
      />
    </>
  );
};

export default SalesPersonList;
