import { Control, Controller } from "react-hook-form";
import CustomizedSelect from "../Custom/CustomizedSelect";
import { SelectProps } from "@mui/material";
import { ISelectOption } from "../../types/global";

type ExtendedSelectProps = SelectProps & {
  name: string;
  label?: string;
  control: Control<any>;
  options: ISelectOption[];
  onChange?: SelectProps["onChange"] | any;
  error?: SelectProps["error"];
  helperText?: string;
  renderValue?: any;
  disabled?: boolean;
  required?: boolean;
  sx?: SelectProps["sx"];
  inputProps?: SelectProps["inputProps"];
  MenuProps?: SelectProps["MenuProps"];
  helperTextSize?: number;
};

const ControlledSelect = ({
  name,
  label,
  control,
  error,
  renderValue,
  options,
  disabled,
  required,
  sx,
  onChange,
  helperText,
  helperTextSize,
  inputProps,
  MenuProps,
}: ExtendedSelectProps) => {
  return (
    <Controller
      key={`${name}-select`}
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedSelect
          {...field}
          onChange={
            onChange
              ? (e) => {
                  onChange(e);
                  field.onChange(e);
                }
              : (e) => {
                  field.onChange(e);
                }
          }
          sx={sx}
          fullWidth
          error={error}
          helperText={helperText}
          helperTextSize={helperTextSize}
          label={label}
          renderValue={renderValue}
          options={options}
          disabled={disabled}
          required={required}
          inputProps={inputProps}
          MenuProps={MenuProps}
        />
      )}
    />
  );
};

export default ControlledSelect;
