import { ICompany } from "../../../types/Setting/Company";

export const companySchema: ICompany = {
  unique_id: "",
  name: "",
  type: "บริษัท",
  identity_no: "",
  register_date: null,
  is_registered_vat: false,
  vat_registration_date: null,
  is_active: 1,
  img_url: [],
  contact_channel_list: [
    {
      contact_channel_type: "phone",
      contact_channel_info: "",
    },
    {
      contact_channel_type: "email",
      contact_channel_info: "",
    },
  ],
  address_list: [
    {
      type: "ที่อยู่จดทะเบียน",
      address: "",
      district: "",
      is_default: true,
      postal_code: "",
      province: "",
      sub_district: "",
      contact_name: "",
      contact_phone: "",
      country: "",
      fax: "",
    },
    {
      type: "ที่อยู่จัดส่งสินค้า",
      address: "",
      district: "",
      is_default: false,
      is_same_as_default_address: false,
      postal_code: "",
      province: "",
      sub_district: "",
      contact_name: "",
      contact_phone: "",
      country: "",
      fax: "",
    },
  ],
};
