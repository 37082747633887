import { Grid, InputAdornment, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { IContact } from "../../../types/Contact/contact";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";
import { useTranslation } from "react-i18next";

const ContactFinance = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IContact>();
  const { t } = useTranslation();
  return (
    <Box mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledNumberTextField
            fullWidth
            control={control}
            name="credit_limit"
            InputProps={{
              endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
            }}
            error={Boolean(errors.credit_limit)}
            helperText={errors.credit_limit?.message?.toString()}
            label={t("contact.financial.credit_limit")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ControlledNumberTextField
            fullWidth
            control={control}
            name="credit_day"
            InputProps={{
              endAdornment: <InputAdornment position="end">วัน</InputAdornment>,
            }}
            error={Boolean(errors.credit_day)}
            helperText={errors.credit_day?.message?.toString()}
            label={t("contact.financial.credit_day")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactFinance;
