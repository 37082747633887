import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import ModalUI from "./ModalUI";
import { IBankAccount } from "../../../types/Setting/Sales";
import ControlledSelect from "../../Controller/ControlledSelect";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  currentId?: number;
  onAddHandler: (data: IBankAccount) => void;
  onUpdateHandler: (data: IBankAccount) => void;
};

const bankAccountSettingSchema: IBankAccount = {
  unique_id: "",
  account_no: "",
  bank_name: "",
  currency: "",
  description: "",
  is_main_account: false,
  name: "",
  payment_channel: "",
  type: "",
};

const BankAccountModal = ({
  open,
  closeModalHandler,
  currentId,
  onAddHandler,
  onUpdateHandler,
}: Props) => {
  //   const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: bankAccountSettingSchema,
    // resolver: yupResolver(),
  });

  const onClose = () => {
    reset();
    closeModalHandler();
  };

  //   useEffect(() => {
  //     if (isSuccess) {
  //       reset(formatLevel1);
  //     }
  //   }, [reset]);

  return (
    <ModalUI
      title={currentId ? t("sentence.edit") : t("button.add")}
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {currentId ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={handleSubmit(onUpdateHandler)}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={handleSubmit(onAddHandler)}
            />
          )}
        </Box>
      }
    >
      <Typography color="primary.main" fontWeight={600} mb={2} fontSize={14}>
        ข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledSelect
            options={[]}
            label={t("setting.sales.bank_account.payment_channel")}
            control={control}
            name="payment_channel"
            error={Boolean(errors?.payment_channel)}
            helperText={
              errors?.payment_channel &&
              errors.payment_channel.message?.toString()
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.unique_id")}
            control={control}
            name="unique_id"
            error={Boolean(errors?.unique_id)}
            helperText={
              errors?.unique_id && errors.unique_id.message?.toString()
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.sales.bank_account.account_no")}
            control={control}
            name="account_no"
            error={Boolean(errors?.account_no)}
            helperText={
              errors?.account_no && errors.account_no.message?.toString()
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.sales.bank_account.name")}
            control={control}
            name="name"
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors.name.message?.toString()}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.sales.bank_account.bank_name")}
            control={control}
            name="bank_name"
            error={Boolean(errors?.bank_name)}
            helperText={
              errors?.bank_name && errors.bank_name.message?.toString()
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledSelect
            options={[]}
            label={t("setting.sales.bank_account.type")}
            control={control}
            name="type"
            error={Boolean(errors?.type)}
            helperText={errors?.type && errors.type.message?.toString()}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.sales.bank_account.currency")}
            control={control}
            name="currency"
            error={Boolean(errors?.currency)}
            helperText={errors?.currency && errors.currency.message?.toString()}
          />
        </Grid>
      </Grid>
      <Typography
        color="primary.main"
        fontWeight={600}
        fontSize={14}
        mt={3}
        mb={2}
      >
        คำอธิบาย
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.description")}
            control={control}
            name="description"
            error={Boolean(errors?.description)}
            helperText={
              errors?.description && errors.description.message?.toString()
            }
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default BankAccountModal;
