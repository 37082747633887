import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AgGrid from "../../UI/AgGrid";
import useUserColumnDefs from "./useUserColumnDefs";
import { IUser } from "../../../types/Auth/user";
import { RowDoubleClickedEvent } from "ag-grid-community";

const UserTable = () => {
  const gridRef = useRef();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<IUser[]>([]);

  useEffect(() => {
    setRowData([]);
  }, []);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { unique_id } = params.data;
    navigate(`/user/account/${unique_id}`);
  };

  const columnDefs = useUserColumnDefs();

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      rowData={rowData}
      path={"/user/account"}
      disabledSidebar
      // onGridReady={onGridReady}
    />
  );
};

export default UserTable;
