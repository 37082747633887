import { useRef } from "react";
import AgGrid from "../../../UI/AgGrid";
import useBankAccountColumnDefs from "./useBankAccountColumnDefs";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
  data: any[];
};

const BankAccountTable = ({ onEditHandler, onDeleteHandler, data }: Props) => {
  const gridRef = useRef(null);
  const columnDefs = useBankAccountColumnDefs(onEditHandler, onDeleteHandler);

  return (
    <AgGrid
      columnDefs={columnDefs}
      ref={gridRef}
      rowData={data || []}
      height={650}
      disabledSidebar
    />
  );
};

export default BankAccountTable;
