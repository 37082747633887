import { useRef } from "react";
import AgGrid from "../../../UI/AgGrid";
import useWarehouseColumnDefs from "./useWarehouseColumnDefs";

type Props = {
  data: any[];
};

const WarehouseTable = ({ data }: Props) => {
  const gridRef = useRef(null);
  const columnDefs = useWarehouseColumnDefs();

  return (
    <AgGrid
      columnDefs={columnDefs}
      ref={gridRef}
      rowData={data || []}
      height={650}
      disabledSidebar
    />
  );
};

export default WarehouseTable;
