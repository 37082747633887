import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";
import { IWarehouse } from "../../../../types/Setting/Inventory";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ITab } from "../../../../types/global";
import InfoTab from "./InfoTab";
import LocationTab from "./LocationTab";
import CustomizedTab from "../../../Custom/CustomizedTab";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  currentId?: number;
  onAddHandler: (data: IWarehouse) => void;
  onUpdateHandler: (data: IWarehouse) => void;
};

const warehouseSettingSchema: IWarehouse = {
  unique_id: "",
  address: {
    address_name: "",
    country: "",
    district: "",
    is_same_as_branch: false,
    postal_code: "",
    province: "",
    sub_district: "",
    region: "",
  },
  branch_id: "",
  description: "",
  name: "",
  supervisor: "",
};

const WarehouseModal = ({
  open,
  closeModalHandler,
  currentId,
  onAddHandler,
  onUpdateHandler,
}: Props) => {
  //   const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();
  const { id } = useParams();

  const [currentTab, setCurrentTab] = useState(pathname);

  const tabs: ITab[] = [
    {
      label: "ข้อมูลทั่วไป",
      path: `${pathname}`,
    },
    {
      label: t("setting.inventory.location.index"),
      path: `${pathname}?tab=location`,
      disabled: !id,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "location":
        setCurrentTab(pathname + "?tab=location");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm({
    defaultValues: warehouseSettingSchema,
    // resolver: yupResolver(),
  });

  const onClose = () => {
    reset();
    closeModalHandler();
  };

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "location":
        return <LocationTab />;
      default:
        return (
          <InfoTab
            control={control}
            errors={errors}
            disabled={false}
            getValues={getValues}
            setValue={setValue}
          />
        );
    }
  };

  //   useEffect(() => {
  //     if (isSuccess) {
  //       reset(formatLevel1);
  //     }
  //   }, [reset]);

  return (
    <ModalUI
      title={currentId ? t("sentence.edit") : t("button.add")}
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {currentId ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={handleSubmit(onUpdateHandler)}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={handleSubmit(onAddHandler)}
            />
          )}
        </Box>
      }
    >
      <CustomizedTab tabs={tabs} currentTab={currentTab} />
      {renderTab(tab)}
    </ModalUI>
  );
};

export default WarehouseModal;
