import { Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controller/ControlledTextField";
import { IContact } from "../../../types/Contact/contact";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

const ContactRemark = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IContact>();
  const { t } = useTranslation();

  return (
    <CustomizedBox margin={0}>
      <Typography color="primary.main" fontWeight={600} mb={2}>
        {t("sentence.remark")}
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12}>
          <ControlledTextField
            placeholder={t("sentence.remark")}
            name="remark"
            control={control}
            error={Boolean(errors.remark)}
            helperText={errors.remark?.message?.toString()}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ContactRemark;
