import { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import { useTranslation } from "react-i18next";

const useWarehouseColumnDefs = () => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName:
          t("setting.unique_id") + t("setting.inventory.warehouse.index"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "name",
        headerName: t("setting.inventory.warehouse.name"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "branch_id",
        headerName: t("setting.inventory.warehouse.branch_id"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "address",
        headerName: t("setting.inventory.warehouse.address"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "supervisor",
        headerName: t("setting.inventory.warehouse.supervisor"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
    ]);
  }, [t]);
  return columnDefs;
};

export default useWarehouseColumnDefs;
