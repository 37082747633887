import { Controller, Control } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  DesktopDatePicker,
  DesktopDatePickerProps,
} from "@mui/x-date-pickers";

type ExtendedDatePickerProps = DesktopDatePickerProps<Date> & {
  name: string;
  control: Control<any>;
  required?: boolean;
};

const ControlledDatePicker = ({
  name,
  control,
  label,
  disabled,
  disablePast,
  required,
}: ExtendedDatePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            format="DD/MM/YYYY"
            label={label}
            onChange={onChange}
            value={value}
            ref={ref}
            disablePast={disablePast}
            disabled={disabled}
            slotProps={{
              textField: {
                required: required,
                fullWidth: true,
                size: "small",
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default ControlledDatePicker;
