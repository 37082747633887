import { IUser } from "../../../types/Auth/user";

export const userSchema: IUser = {
  title_name: "",
  nick_name: "",
  first_name: "",
  last_name: "",
  gender: "",
  start_date: null,
  position: "",
  department: "",
  employee_type: "",
  company_name: "",
  created_date: null,
  email: "",
  img_url: [],
  is_sales_employee: false,
  last_updated_date: null,
  original_branch: "",
  phone: "",
  status: 0,
  unique_id: "",
};
