import { ICompany } from "../../../types/Setting/Company";
import { useFormContext, useWatch } from "react-hook-form";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Typography, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controller/ControlledTextField";
import DropzoneUI from "../../UI/DropzoneUI";
import ControlledSwitch from "../../Controller/ControlledSwitch";
import ControlledSelect from "../../Controller/ControlledSelect";
import { useState } from "react";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { ISelectOption } from "../../../types/global";
import ControlledCheckbox from "../../Controller/ControlledCheckbox";

const CompanyDetail = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<ICompany>();
  const [identityNoError, setIdentityNoError] = useState<boolean>(false);
  const watchRegisterVat = useWatch({ control, name: "is_registered_vat" });

  const { t } = useTranslation();

  const corporateTypes: ISelectOption[] = [
    {
      label: t("setting.company.corporate_type.company"),
      value: "บริษัท",
    },
    {
      label: t("setting.company.corporate_type.public_limited"),
      value: "บริษัทมหาชนจำกัด",
    },
    {
      label: t("setting.company.corporate_type.limited_partnership"),
      value: "ห้างหุ้นส่วนจำกัด",
    },
    {
      label: t("setting.company.corporate_type.juristic_partnership"),
      value: "ห้างหุ้นส่วนสามัญนิติบุคคล",
    },
    {
      label: t("setting.company.corporate_type.association"),
      value: "สมาคม",
    },
    { label: t("setting.company.corporate_type.other"), value: "อื่นๆ" },
  ];

  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Typography fontWeight={600} color="primary.main" mb={2}>
            {t("setting.company.index")}
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12}>
              <ControlledTextField
                name="unique_id"
                control={control}
                label={`รหัสกิจการ`}
                error={Boolean(errors.unique_id)}
                helperText={errors.unique_id?.message?.toString()}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledTextField
                name="name"
                control={control}
                label={t("setting.company.name")}
                error={Boolean(errors.name)}
                helperText={errors.name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledSelect
                name="type"
                control={control}
                label={t("setting.company.corporate_type.index")}
                error={Boolean(errors.type)}
                helperText={errors.type?.message?.toString()}
                options={corporateTypes}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledTextField
                name="identity_no"
                control={control}
                label={t("setting.company.identity_no")}
                error={Boolean(errors.identity_no) || identityNoError}
                helperText={
                  identityNoError
                    ? "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"
                    : errors.identity_no?.message
                }
                onChange={(e, field) => {
                  if (e.target.value.length > 13) {
                    setIdentityNoError(true);
                  } else {
                    setIdentityNoError(false);
                  }
                  field.onChange(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ControlledDatePicker
                name="register_date"
                control={control}
                label={t("setting.company.register_date")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ControlledCheckbox
                control={control}
                name="is_registered_vat"
                label={t("setting.company.is_registered_vat")}
              />
            </Grid>
            {watchRegisterVat && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledDatePicker
                  name="vat_registration_date"
                  control={control}
                  label={t("setting.company.vat_registration_date")}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            รูปภาพ
          </Typography>
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={false}
          />
          <Box mt={2}>
            <ControlledSwitch
              control={control}
              name="status"
              label="ใช้งาน"
              disabled={false}
            />
          </Box>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default CompanyDetail;
