import { Box, BoxProps, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

interface Props {
  status?: "active" | "inactive" | string | null;
  text?: string;
}

const StyledStatus = styled(Box)<BoxProps>(({ bgcolor }) => ({
  "&": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: bgcolor,
    padding: ".25rem 0",
    minWidth: 95,
  },
}));

const CustomizedStatus = ({ status, text }: Props) => {
  const { t } = useTranslation();
  switch (status) {
    case "pre_sales":
    case "ร่าง":
    case "draft":
      return (
        <StyledStatus bgcolor="#E6E6E6">
          <Typography variant="button" sx={{ color: "#333333" }}>
            {text ?? t("status.draft")}
          </Typography>
        </StyledStatus>
      );
    case "active":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <Typography variant="button" sx={{ color: "#246527" }}>
            {t("status.active")}
          </Typography>
        </StyledStatus>
      );

    case "is_active":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <Typography variant="button" sx={{ color: "#C3762E" }}>
            รอสแกน
          </Typography>
        </StyledStatus>
      );
    case "is_scan":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <Typography variant="button" sx={{ color: "#246527" }}>
            สแกนแล้ว
          </Typography>
        </StyledStatus>
      );
    case "post_sales":
    case "รอโอนย้าย":
    case "wait_transfer":
      return (
        <StyledStatus bgcolor="#C4DBFD">
          <Typography variant="button" sx={{ color: "#1F5BB2" }}>
            {text ?? t("status.wait_transfer")}
          </Typography>
        </StyledStatus>
      );
    case "เสร็จสิ้น":
    case "finished":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <Typography variant="button" sx={{ color: "#246527" }}>
            {text ?? t("status.finished")}
          </Typography>
        </StyledStatus>
      );
    case "inactive":
      return (
        <StyledStatus bgcolor="#FFA396">
          <Typography variant="button" sx={{ color: "#B54839" }}>
            {t("status.in_active")}
          </Typography>
        </StyledStatus>
      );
    case "ยกเลิก":
    case "cancelled":
      return (
        <StyledStatus bgcolor="#D3D3D3">
          <Typography
            variant="button"
            sx={{
              color: "#FFFFFF",
            }}
          >
            {text ?? t("status.cancelled")}
          </Typography>
        </StyledStatus>
      );
    case "late":
      return (
        <StyledStatus bgcolor="#FFF2EB">
          <Typography
            variant={"button"}
            sx={{
              color: "#CC6200",
            }}
          >
            {t("status.late")}
          </Typography>
        </StyledStatus>
      );
    case "support":
    case "รออนุมัติ":
    case "wait_approve":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <Typography
            variant={"button"}
            sx={{
              color: "#C3762E",
            }}
          >
            {text ?? t("status.wait_approve")}
          </Typography>
        </StyledStatus>
      );
    case "รอตอบรับ":
    case "wait_accept":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <Typography
            variant={"button"}
            sx={{
              color: "#C3762E",
            }}
          >
            {t("status.wait_accept")}
          </Typography>
        </StyledStatus>
      );
    case "ตอบรับแล้ว":
    case "accepted":
      return (
        <StyledStatus bgcolor="#C4DBFD">
          <Typography
            variant={"button"}
            sx={{
              color: "#1F5BB2",
            }}
          >
            {t("status.accepted")}
          </Typography>
        </StyledStatus>
      );
    case "ไม่อนุมัติ":
    case "not_approved":
      return (
        <StyledStatus bgcolor="#FEECEB">
          <Typography
            variant={"button"}
            sx={{
              color: "#B54839",
            }}
          >
            {t("status.not_approved")}
          </Typography>
        </StyledStatus>
      );
    case "อนุมัติแล้ว":
    case "approved":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <Typography
            variant={"button"}
            sx={{
              color: "#1F5BB2",
            }}
          >
            {t("status.approved")}
          </Typography>
        </StyledStatus>
      );
    case "is_main_bom":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <Typography
            variant={"button"}
            sx={{
              color: "#1F5BB2",
            }}
          >
            {t("status.is_main_bom")}
          </Typography>
        </StyledStatus>
      );
    case "รอผลิต":
    case "pending_manu":
      return (
        <StyledStatus bgcolor="#FFF1C5">
          <Typography
            variant={"button"}
            sx={{
              color: "#C3762E",
            }}
          >
            {t("status.pending_manu")}
          </Typography>
        </StyledStatus>
      );
    case "in_progress":
    case "กำลังผลิต":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <Typography
            variant={"button"}
            sx={{
              color: "#1F5BB2",
            }}
          >
            {t("status.manufacturing")}
          </Typography>
        </StyledStatus>
      );
    case "สั่งซื้อแล้วบางส่วน":
    case "partially_ordered":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <Typography
            variant={"button"}
            sx={{
              color: "#246527",
            }}
          >
            {t("status.partially_ordered")}
          </Typography>
        </StyledStatus>
      );
    case "สั่งซื้อแล้ว":
    case "fully_ordered":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <Typography
            variant={"button"}
            sx={{
              color: "#246527",
            }}
          >
            {t("status.fully_ordered")}
          </Typography>
        </StyledStatus>
      );
    case "นำเข้าแล้วบางส่วน":
    case "partially_imported":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <Typography
            variant={"button"}
            sx={{
              color: "#246527",
            }}
          >
            {t("status.partially_imported")}
          </Typography>
        </StyledStatus>
      );
    case "นำเข้าแล้ว":
    case "fully_imported":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <Typography
            variant={"button"}
            sx={{
              color: "#246527",
            }}
          >
            {t("status.fully_imported")}
          </Typography>
        </StyledStatus>
      );
    default:
      return null;
  }
};

export default CustomizedStatus;
