import { Box, Grid, Typography } from "@mui/material";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { useTranslation } from "react-i18next";
import { IDefaultForm } from "../../../../types/global";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ControlledCheckbox from "../../../Controller/ControlledCheckbox";

const InfoTab = ({ control, errors }: IDefaultForm) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography color="primary.main" fontWeight={600} mb={2} fontSize={14}>
        ข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.inventory.warehouse.branch_id")}
            control={control}
            name="branch_id"
            error={Boolean(errors?.branch_id)}
            helperText={
              errors?.branch_id && errors.branch_id.message?.toString()
            }
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={
              t("setting.unique_id") + t("setting.inventory.warehouse.index")
            }
            control={control}
            name="unique_id"
            error={Boolean(errors?.unique_id)}
            helperText={
              errors?.unique_id && errors.unique_id.message?.toString()
            }
            disabled
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.inventory.warehouse.name")}
            control={control}
            name="name"
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors.name.message?.toString()}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("setting.inventory.warehouse.supervisor")}
            control={control}
            name="supervisor"
            error={Boolean(errors?.supervisor)}
            helperText={
              errors?.supervisor && errors.supervisor.message?.toString()
            }
          />
        </Grid>
      </Grid>
      <CustomizedBox
        bgcolor="primary.light"
        margin={"1.5rem 0 0 0"}
        padding={"1rem"}
      >
        <Box mb={1}>
          <ControlledCheckbox
            control={control}
            name="address.is_same_as_branch"
            label="ใช้ที่อยู่ตามสาขา"
          />
        </Box>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <ControlledTextField
              label={"ที่อยู่"}
              control={control}
              name="address.address_name"
              error={Boolean(errors?.address && errors?.address.address_name)}
              helperText={
                errors?.address &&
                errors?.address.address_name &&
                errors.address.address_name.message?.toString()
              }
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <ControlledTextField
              label={"แขวง/ตำบล"}
              control={control}
              name="address.sub_district"
              error={Boolean(errors?.address && errors?.address.sub_district)}
              helperText={
                errors?.address &&
                errors?.address.sub_district &&
                errors.address.sub_district.message?.toString()
              }
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <ControlledTextField
              label={"เขต/อำเภอ"}
              control={control}
              name="address.district"
              error={Boolean(errors?.address && errors?.address.district)}
              helperText={
                errors?.address &&
                errors?.address.district &&
                errors.address.district.message?.toString()
              }
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <ControlledTextField
              label={"จังหวัด"}
              control={control}
              name="address.province"
              error={Boolean(errors?.address && errors?.address.province)}
              helperText={
                errors?.address &&
                errors?.address.province &&
                errors.address.province.message?.toString()
              }
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <ControlledTextField
              label={"รหัสไปรษณีย์"}
              control={control}
              name="address.postal_code"
              error={Boolean(errors?.address && errors?.address.postal_code)}
              helperText={
                errors?.address &&
                errors?.address.postal_code &&
                errors.address.postal_code.message?.toString()
              }
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <ControlledTextField
              label={"ภาค"}
              control={control}
              name="address.region"
              error={Boolean(errors?.address && errors?.address.region)}
              helperText={
                errors?.address &&
                errors?.address.region &&
                errors.address.region.message?.toString()
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <ControlledTextField
              label={"ประเทศ"}
              control={control}
              name="address.country"
              error={Boolean(errors?.address && errors?.address.country)}
              helperText={
                errors?.address &&
                errors?.address.country &&
                errors.address.country.message?.toString()
              }
            />
          </Grid>
        </Grid>
      </CustomizedBox>
      <Typography
        color="primary.main"
        fontWeight={600}
        fontSize={14}
        mt={3}
        mb={2}
      >
        รายละเอียด
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={"รายละเอียด"}
            control={control}
            name="description"
            error={Boolean(errors?.description)}
            helperText={
              errors?.description && errors.description.message?.toString()
            }
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoTab;
