import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { FormProvider, useForm } from "react-hook-form";
import { ICompany } from "../../../types/Setting/Company";
import CompanyContactChannel from "../../../components/Form/Company/ContactChannel";
import CompanyAddress from "../../../components/Form/Company/Address";
import { companySchema } from "../../../components/Form/Company/schema";
import CompanyDetail from "../../../components/Form/Company/Detail";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";

const CompanySetting = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.company.index"),
      to: "/setting/company",
    },
  ];

  const methods = useForm<ICompany>({
    defaultValues: companySchema,
  });

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <FormProvider {...methods}>
        <CompanyDetail />
        <CompanyContactChannel />
        <CustomizedBox>
          <CompanyAddress />
        </CustomizedBox>
      </FormProvider>
      ;
    </>
  );
};

export default CompanySetting;
