import { Box, Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledSelect from "../../Controller/ControlledSelect";
import ControlledSwitch from "../../Controller/ControlledSwitch";
import DropzoneUI from "../../UI/DropzoneUI";
import { useState } from "react";
import RadioBox from "../../UI/RadioBox";
type Props = {
  type: "customer" | "vendor";
};

const ContactDetail = ({ type }: Props) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation();

  const [businessOption, setBusinessOption] = useState<string>("none");
  return (
    <CustomizedBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            รายละเอียด{t(`contact.contact_type.${type}`)}
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12}>
              <ControlledTextField
                name="unique_id"
                control={control}
                label={`รหัส${t(`contact.contact_type.${type}`)}`}
                error={Boolean(errors.unique_id)}
                helperText={errors.unique_id?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <RadioBox
                onClick={() => {
                  setBusinessOption("cooperate");
                }}
                label="สำนักงานใหญ่"
                checked={businessOption === "cooperate"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <RadioBox
                onClick={() => {
                  setBusinessOption("branch");
                }}
                label="สาขา"
                checked={businessOption === "branch"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <RadioBox
                onClick={() => {
                  setBusinessOption("none");
                }}
                label="ไม่ระบุ"
                checked={businessOption === "none"}
              />
            </Grid>
            {businessOption === "branch" && (
              <Grid item xs={12} sm={12}>
                <ControlledTextField
                  name="branch_unique_id"
                  control={control}
                  label={`รหัส${t("contact.branch")}`}
                  error={Boolean(errors.branch_unique_id)}
                  helperText={errors.branch_unique_id?.message?.toString()}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name="business_type"
                control={control}
                label={t("contact.info.business_type")}
                error={Boolean(errors.business_type)}
                helperText={errors.business_type?.message?.toString()}
                options={[]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name="bussiness_type_detail"
                control={control}
                label={t("contact.info.coperate_type.index")}
                error={Boolean(errors.bussiness_type_detail)}
                helperText={errors.bussiness_type_detail?.message?.toString()}
                options={[]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name="name"
                control={control}
                label={t("contact.info.brand_name")}
                error={Boolean(errors.name)}
                helperText={errors.name?.message?.toString()}
                options={[]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextField
                name="taxpayer_id"
                control={control}
                label={t("contact.info.taxpayer_id")}
                error={Boolean(errors.taxpayer_id)}
                helperText={errors.taxpayer_id?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name="source"
                control={control}
                label={t(`contact.info.source.${type}`)}
                error={Boolean(errors.source)}
                helperText={errors.source?.message?.toString()}
                options={[]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name="type"
                control={control}
                label={t(`contact.info.type.${type}`)}
                error={Boolean(errors.type)}
                helperText={errors.type?.message?.toString()}
                options={[]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name="group"
                control={control}
                label={t(`contact.info.group.${type}`)}
                error={Boolean(errors.group)}
                helperText={errors.group?.message?.toString()}
                options={[]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name="zone"
                control={control}
                label={t(`contact.info.zone`)}
                error={Boolean(errors.zone)}
                helperText={errors.zone?.message?.toString()}
                options={[]}
              />
            </Grid>
            {type === "customer" && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledSelect
                  name="sales_channel"
                  control={control}
                  label={t(`contact.info.sales_channel`)}
                  error={Boolean(errors.sales_channel)}
                  helperText={errors.sales_channel?.message?.toString()}
                  options={[]}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Typography color="primary.main" fontWeight={600} mb={2}>
            รูปภาพ
          </Typography>
          <DropzoneUI
            control={control}
            name={"img_url"}
            setValue={setValue}
            acceptedFileType="image"
            maxSize={1}
            disabled={false}
          />
          <Box mt={2}>
            <ControlledSwitch
              control={control}
              name="status"
              label="ใช้งาน"
              disabled={false}
            />
          </Box>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ContactDetail;
