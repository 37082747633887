import "./App.css";
import "./i18n/i18n";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./components/UI/Dashboard/Dashboard";
import Landing from "./pages/Landing";
import User from "./pages/User";
import UserAccount from "./pages/User/Account";
import UserAccountContainer from "./pages/User/Account/Container";
import Setting from "./pages/Setting";
import EmployeeSetting from "./pages/Setting/Employee";
import DepartmentSetting from "./pages/Setting/Employee/DepartmentSetting";
import PositionSetting from "./pages/Setting/Employee/PositionSetting";
import ContactSetting from "./pages/Setting/Contact";
import VendorTypeSetting from "./pages/Setting/Contact/VendorTypeSetting";
import CustomerTypeSetting from "./pages/Setting/Contact/CustomerTypeSetting";
import CustomerGroupSetting from "./pages/Setting/Contact/CustomerGroupSetting";
import VendorGroupSetting from "./pages/Setting/Contact/VendorGroupSetting";
import SalesSetting from "./pages/Setting/Sales";
import SalesTypeSetting from "./pages/Setting/Sales/SalesTypeSetting";
import SalesChannelSetting from "./pages/Setting/Sales/SalesChannelSetting";
import BankAccountSetting from "./pages/Setting/Sales/BankAccountSetting";
import InventorySetting from "./pages/Setting/Inventory";
import GroupCategorySetting from "./pages/Setting/Inventory/GroupCategorySetting";
import CategorySetting from "./pages/Setting/Inventory/CategorySetting";
import SubCategorySetting from "./pages/Setting/Inventory/SubCategorySetting";
import BrandSetting from "./pages/Setting/Inventory/BrandSetting";
import SegmentSetting from "./pages/Setting/Inventory/SegmentSetting";
import SeriesSetting from "./pages/Setting/Inventory/SeriesSetting";
import WarehouseSetting from "./pages/Setting/Inventory/WarehouseSetting";
import UomSetting from "./pages/Setting/Inventory/UomSetting";
import ModelSetting from "./pages/Setting/Inventory/ModelSetting";
import AttributeSetting from "./pages/Setting/Inventory/AttributeSetting";
import LocationSetting from "./pages/Setting/Inventory/LocationSetting";
import Contact from "./pages/Contact";
import Customer from "./pages/Contact/Customer";
import Vendor from "./pages/Contact/Vendor";
import CustomerContainer from "./pages/Contact/Customer/Container";
import VendorContainer from "./pages/Contact/Vendor/Container";
import CompanySetting from "./pages/Setting/Company";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/landing" />}></Route>
      <Route element={<DashboardLayout />}>
        <Route path="/landing" element={<Landing />} />
        <Route path="/contact">
          <Route index element={<Contact />} />
          <Route path="customer">
            <Route index element={<Customer />} />
            <Route path="add" element={<CustomerContainer />} />
            <Route path=":id" element={<CustomerContainer />} />
          </Route>
          <Route path="vendor">
            <Route index element={<Vendor />} />
            <Route path="add" element={<VendorContainer />} />
            <Route path=":id" element={<VendorContainer />} />
          </Route>
        </Route>
        <Route path="/user">
          <Route index element={<User />} />
          <Route path="account">
            <Route index element={<UserAccount />} />
            <Route path="add" element={<UserAccountContainer />} />
            <Route path=":id" element={<UserAccountContainer />} />
          </Route>
        </Route>
        <Route path="/setting">
          <Route index element={<Setting />} />
          <Route path="company">
            <Route index element={<CompanySetting />} />
          </Route>
          <Route path="contact">
            <Route index element={<ContactSetting />} />
            <Route path="customer-type" element={<CustomerTypeSetting />} />
            <Route path="vendor-type" element={<VendorTypeSetting />} />
            <Route path="customer-group" element={<CustomerGroupSetting />} />
            <Route path="vendor-group" element={<VendorGroupSetting />} />
          </Route>
          <Route path="inventory">
            <Route index element={<InventorySetting />} />
            <Route path="group-category" element={<GroupCategorySetting />} />
            <Route path="category" element={<CategorySetting />} />
            <Route path="sub-category" element={<SubCategorySetting />} />
            <Route path="brand" element={<BrandSetting />} />
            <Route path="segment" element={<SegmentSetting />} />
            <Route path="series" element={<SeriesSetting />} />
            <Route path="warehouse" element={<WarehouseSetting />} />
            <Route path="location" element={<LocationSetting />} />
            <Route path="uom" element={<UomSetting />} />
            <Route path="model" element={<ModelSetting />} />
            <Route path="attribute" element={<AttributeSetting />} />
          </Route>
          <Route path="sales">
            <Route index element={<SalesSetting />} />
            <Route path="type" element={<SalesTypeSetting />} />
            <Route path="channel" element={<SalesChannelSetting />} />
            <Route path="bank-account" element={<BankAccountSetting />} />
          </Route>
          <Route path="employee">
            <Route index element={<EmployeeSetting />} />
            <Route path="department" element={<DepartmentSetting />} />
            <Route path="position" element={<PositionSetting />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
