import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { useNavbar } from "../../../hooks/use-navbar";
import LeftNavbar from "../Navbar/LeftNavbar";
import TopNavbar from "../Navbar/TopNavbar";
import DashboardContainer from "./DashboardContainer";

const DashboardLayout = () => {
  const theme = useTheme();
  const navbar = useNavbar();

  return (
    <Box>
      <DashboardContainer open={navbar?.sidebar.isSidebarOpen} theme={theme}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            height: "100%",
            maxWidth: "1650px",
            p: 3,
          }}
        >
          <Outlet />
        </Box>
      </DashboardContainer>
      <TopNavbar />
      <LeftNavbar />
    </Box>
  );
};

export default DashboardLayout;
