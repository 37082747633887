import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import PermissionTab from "./PermissionTab";
import AccountInfoTab from "./AccountInfoTab";
import { Box } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { userSchema } from "../../../components/Form/User/schema";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";

const UserAccountContainer = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [currentTab, setCurrentTab] = useState(pathname);

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
      to: "/user/account",
    },
    {
      name: t("user.account.add_new_account"),
    },
  ];

  const tabs = [
    {
      label: `ข้อมูล${t("user.account.index")}`,
      path: `${pathname}`,
    },
    {
      label: t("user.account.permission"),
      path: `${pathname}?tab=permission`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "permission":
        setCurrentTab(pathname + "?tab=permission");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "permission":
        return <PermissionTab />;
      default:
        return <AccountInfoTab />;
    }
  };

  const methods = useForm({
    defaultValues: userSchema,
  });

  const selectModifyOptions = [
    {
      value: "แก้ไข",
      disabled: false,
    },
    {
      value: "ลบ",
      disabled: false,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent={"flex-end"}
        flexGrow={1}
        gap={1}
        maxWidth={1040}
      >
        <CustomizedMenuOptions
          size="small"
          label={"ตัวเลือก"}
          options={selectModifyOptions}
          onSelect={(e) => {
            const value = e.target as HTMLElement;
            switch (value.innerText) {
              case "แก้ไข":
                // setDisabled(false);
                break;
              case "ลบ":
                // setDeleteModalIsOpen(true);
                break;

              default:
                break;
            }
          }}
          disabled={false}
        />
        <CustomizedButton title="ยกเลิก" variant="outlined" />
        <CustomizedButton title="ดำเนินการต่อ" variant="contained" />
      </Box>
      <Box mt={3}>
        <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
      </Box>
      <FormProvider {...methods}>{renderTab(tab)}</FormProvider>
    </>
  );
};

export default UserAccountContainer;
